import React, { useState, useEffect } from "react";
// import { FaHome, FaPhoneAlt } from "react-icons/fa";
import { TfiClose } from "react-icons/tfi";
import { RxHamburgerMenu } from "react-icons/rx";
import Logo from "../images/cubiksoft_logo.png";
import { gsap } from "gsap";
const Navbar = ({ navRef }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  useEffect(() => {
    gsap.timeline({}).fromTo(
      ".navigation_bar",
      {
        autoAlpha: 0,
      },
      {
        autoAlpha: 1,
        duration: 0.5,
        ease: "power3.inOut",
      }
    );
  }, []);
  const handleMenuOpenClick = () => {
    document.querySelector("body").classList.add("menu_open");
    document.querySelector("html").classList.add("menu_open");
    const menuTl = gsap
      .timeline({})
      .to(".mobile_menu", {
        maxHeight: "100vh",
        duration: 0.5,
      })
      .fromTo(
        ".mobile_menu_item",
        {
          autoAlpha: 0,
          x: -20,
        },
        {
          autoAlpha: 1,
          x: 0,
          duration: 0.5,
          stagger: 0.05,
        }
      );
  };
  const closeMenu = () => {
    document.querySelector("body").classList.remove("menu_open");
    document.querySelector("html").classList.remove("menu_open");
    setMenuOpen(false);
    const menuTl = gsap
      .timeline({})
      .fromTo(
        ".mobile_menu_item",
        {
          autoAlpha: 1,
        },
        {
          autoAlpha: 0,
          duration: 0.5,
          stagger: 0.05,
          delay: -0.5,
        }
      )
      .to(".mobile_menu", {
        maxHeight: "0",
        duration: 0.5,
      });
  };
  return (
    <>
      <div className="navigation_bar" ref={(el) => (navRef.current[0] = el)}>
        <div className="container">
          <div className="navigation_bar_wrapper">
            <div className="navbar_logo" ref={(el) => (navRef.current[1] = el)}>
              <img src={Logo} alt="CubikSoft" />
            </div>
            <div className="navbar_menu" ref={(el) => (navRef.current[2] = el)}>
              <div className="navbar_menu_item">
                <a
                  href="/"
                  className={({ isActive }) =>
                    "navbar_link" + (isActive ? " active_link" : "")
                  }
                >
                  <div className="navbar_menu_item_inner">Home</div>
                </a>
              </div>
              <div className="navbar_menu_item">
                <a
                  href="#about"
                  className={({ isActive }) =>
                    "navbar_link" + (isActive ? " active_link" : "")
                  }
                >
                  <div className="navbar_menu_item_inner">About</div>
                </a>
              </div>

              <div className="navbar_menu_item">
                <a
                  href="#services"
                  className={({ isActive }) =>
                    "navbar_link" + (isActive ? " active_link" : "")
                  }
                >
                  <div className="navbar_menu_item_inner">Services</div>
                </a>
              </div>
              {/* <div className="navbar_menu_item">
                <a
                  href="#testimonials"
                  className={({ isActive }) =>
                    "navbar_link" + (isActive ? " active_link" : "")
                  }
                >
                  <div className="navbar_menu_item_inner">Testimonials</div>
                </a>
              </div> */}
              {/* <div className="navbar_menu_item">
                <a
                  href="#clients"
                  className={({ isActive }) =>
                    "navbar_link" + (isActive ? " active_link" : "")
                  }
                >
                  <div className="navbar_menu_item_inner">Clients</div>
                </a>
              </div> */}
              <div className="navbar_menu_item">
                <a
                  href="#contact"
                  className={({ isActive }) =>
                    "navbar_link" + (isActive ? " active_link" : "")
                  }
                >
                  <div className="navbar_menu_item_inner">Contact</div>
                </a>
              </div>
            </div>
            <button className="menu_toggle" onClick={handleMenuOpenClick}>
              <RxHamburgerMenu />
            </button>{" "}
            <button className="close_button" onClick={closeMenu}>
              <TfiClose />
            </button>
          </div>
        </div>
      </div>
      <div className="mobile_menu">
        <div className="mobile_menu_inner">
          <div className="container">
            <div className="mobile_menu_items">
              <div className="mobile_menu_item">
                <a
                  href="/"
                  className={({ isActive }) =>
                    "navbar_link" + (isActive ? " active_link" : "")
                  }
                  onClick={closeMenu}
                >
                  <div className="mobile_menu_item_inner">Home</div>
                </a>
              </div>
              <div className="mobile_menu_item">
                <a
                  href="#about"
                  className={({ isActive }) =>
                    "navbar_link" + (isActive ? " active_link" : "")
                  }
                  onClick={closeMenu}
                >
                  <div className="mobile_menu_item_inner">About</div>
                </a>
              </div>

              <div className="mobile_menu_item">
                <a
                  href="#services"
                  className={({ isActive }) =>
                    "navbar_link" + (isActive ? " active_link" : "")
                  }
                  onClick={closeMenu}
                >
                  <div className="mobile_menu_item_inner">Services</div>
                </a>
              </div>
              <div className="mobile_menu_item">
                <a
                  href="#contact"
                  className={({ isActive }) =>
                    "navbar_link" + (isActive ? " active_link" : "")
                  }
                  onClick={closeMenu}
                >
                  <div className="mobile_menu_item_inner">Contact</div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
