import React from "react";

const SectionLayout = ({ children, title, section_id }) => {
  return (
    <section id={section_id} className={`section_layout section_vh `}>
      {children}
      <div className="section_layout_title_wrapper">
        <h2 className="section_layout_title">{title}</h2>
      </div>
    </section>
  );
};

export default SectionLayout;
