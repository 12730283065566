import React from "react";
import SectionLayout from "../components/SectionLayout";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { RiDoubleQuotesL, RiDoubleQuotesR } from "react-icons/ri";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import alan from "../images/clients/alan_solution.png";
import appInnovation from "../images/clients/app_innovation.png";
import appranix from "../images/clients/appranix.png";
import ardhas from "../images/clients/ardhas.png";
import arient from "../images/clients/arient_solutions.png";
import b from "../images/clients/b.png";
import cadopt from "../images/clients/cadopt.png";
import cds from "../images/clients/cds.png";
import cloud from "../images/clients/cloud_i5.png";
import cloudq from "../images/clients/cloudq.png";
import codesense from "../images/clients/codesense.png";
import contigo from "../images/clients/contigo_tech.png";
import emis from "../images/clients/emis.png";
import equator from "../images/clients/equator.png";
import gateway from "../images/clients/gateway.png";
import indium from "../images/clients/indium.png";
import infinijith from "../images/clients/infinijith.png";
import kopa from "../images/clients/kopa.png";
import mt from "../images/clients/mt.png";
import nous from "../images/clients/nous.png";
import o from "../images/clients/o.png";
import payoda from "../images/clients/payoda.png";
import pinnacle from "../images/clients/pinnacle.png";
import powerup from "../images/clients/power_up.png";
import ppts from "../images/clients/ppts.png";
import qsoft from "../images/clients/q_soft.png";
import qqresearch from "../images/clients/qq_research.png";
import scoto from "../images/clients/scoto.png";
import sierra from "../images/clients/sierra.png";
import skava from "../images/clients/skava.png";
import skein from "../images/clients/skein.png";
import skyrand from "../images/clients/skyrand.png";
import squad from "../images/clients/squad_m.png";
import staqu from "../images/clients/staqu.png";
import technoduce from "../images/clients/technoduce.png";
import technomax from "../images/clients/technomax.png";
import together from "../images/clients/together.png";
import venus from "../images/clients/venu_software.png";
import vitalmed from "../images/clients/vitalmed.png";
import welkyn from "../images/clients/welkyn.png";

const Client = () => {
  return (
    <SectionLayout section_id="clients" title="Clients">
      <div className="clients_wrapper">
        <div className="container">
          <div className="client_top_content">
            <h2 className="display-3 mb-5">Our Clients</h2>
          </div>
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
            // loop={true}
            grabCursor
            spaceBetween={30}
            slidesPerView="5"
            speed={2000}
            autoplay={{ delay: 0 }}
            navigation={{
              nextEl: ".review_navigation .next-button",
              prevEl: ".review_navigation .prev-button",
            }}
            breakpoints={{
              // when window width is >= 640px
              0: {
                slidesPerView: 2,
              },

              // when window width is >= 768px
              768: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 4,
              },
              1200: {
                slidesPerView: 5,
              },
            }}
            // scrollbar={{ draggable: true }}
            className="review_slider"
          >
            {/* <div className="clients_list"> */}
            {ClientsListData.map((item, index) => (
              <SwiperSlide className="clients_list_item_slider" key={index}>
                <div className="clients_list_item" key={index}>
                  <img src={item.imgSrc} alt="" />
                </div>
              </SwiperSlide>
            ))}
            {/* </div> */}
          </Swiper>
          <div className="review_navigation">
            <button className="prev-button">
              <AiOutlineLeft />
            </button>
            <button className="next-button">
              <AiOutlineRight />
            </button>
          </div>
        </div>
      </div>
    </SectionLayout>
  );
};

const ClientsListData = [
  {
    id: 1,
    imgSrc: alan,
  },
  {
    id: 2,
    imgSrc: appInnovation,
  },
  {
    id: 3,
    imgSrc: appranix,
  },
  {
    id: 4,
    imgSrc: ardhas,
  },
  {
    id: 5,
    imgSrc: arient,
  },
  {
    id: 6,
    imgSrc: b,
  },
  {
    id: 7,
    imgSrc: cadopt,
  },
  {
    id: 8,
    imgSrc: cds,
  },
  {
    id: 9,
    imgSrc: cloud,
  },
  {
    id: 10,
    imgSrc: cloudq,
  },
  {
    id: 11,
    imgSrc: codesense,
  },
  {
    id: 12,
    imgSrc: contigo,
  },
  {
    id: 13,
    imgSrc: emis,
  },
  {
    id: 14,
    imgSrc: equator,
  },
  {
    id: 15,
    imgSrc: gateway,
  },
  {
    id: 16,
    imgSrc: indium,
  },
  {
    id: 17,
    imgSrc: infinijith,
  },
  {
    id: 18,
    imgSrc: kopa,
  },
  {
    id: 19,
    imgSrc: mt,
  },
  {
    id: 20,
    imgSrc: nous,
  },
  {
    id: 21,
    imgSrc: o,
  },
  {
    id: 22,
    imgSrc: payoda,
  },
  {
    id: 23,
    imgSrc: pinnacle,
  },
  {
    id: 24,
    imgSrc: powerup,
  },
  {
    id: 25,
    imgSrc: ppts,
  },
  {
    id: 26,
    imgSrc: qsoft,
  },
  {
    id: 27,
    imgSrc: qqresearch,
  },
  {
    id: 28,
    imgSrc: scoto,
  },
  {
    id: 29,
    imgSrc: sierra,
  },
  {
    id: 30,
    imgSrc: skava,
  },
  {
    id: 31,
    imgSrc: skein,
  },
  {
    id: 32,
    imgSrc: skyrand,
  },
  {
    id: 33,
    imgSrc: squad,
  },
  {
    id: 34,
    imgSrc: staqu,
  },
  {
    id: 35,
    imgSrc: technoduce,
  },
  {
    id: 36,
    imgSrc: technomax,
  },
  {
    id: 37,
    imgSrc: together,
  },
  {
    id: 38,
    imgSrc: venus,
  },
  {
    id: 39,
    imgSrc: vitalmed,
  },
  {
    id: 40,
    imgSrc: welkyn,
  },
];
export default Client;
