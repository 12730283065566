import "./styles/app.scss";
import { gsap } from "gsap";
import Layout from "./pages/Layout";
import { useEffect } from "react";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { Helmet } from "react-helmet";

function App() {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
  }, []);
  return (
    <div className="app">
      <Layout />
    </div>
  );
}

export default App;
