import React from "react";
import SectionLayout from "../components/SectionLayout";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { RiDoubleQuotesL, RiDoubleQuotesR } from "react-icons/ri";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
const Testimonial = () => {
  return (
    <SectionLayout title="Reviews" section_id="testimonials">
      <div className="testimonial_wrapper ">
        <div className="testimonial_wrapper_bg">
          <RiDoubleQuotesL />
        </div>
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          // loop={true}
          spaceBetween={0}
          slidesPerView="1"
          speed={1000}
          autoplay={{ delay: 10000 }}
          navigation={{
            nextEl: ".testimonial_navigation .next-button",
            prevEl: ".testimonial_navigation .prev-button",
          }}
          // scrollbar={{ draggable: true }}
          className="testimonial_slider"
        >
          {homeReviewsContent.map((item, index) => (
            <SwiperSlide className="testimonial_slider_item" key={index}>
              <div className="testimonial_slider_item_wrapper">
                <p className="paragraph">{item.reviewContent}</p>
                <div className="testimonial_slider_item_name mt-3">
                  <h5>{item.reviewName}</h5>
                  <p>{item.reviewProfile}</p>
                </div>
              </div>
            </SwiperSlide>
          ))}
          <div className="testimonial_navigation">
            <button className="prev-button">
              <AiOutlineLeft />
            </button>
            <button className="next-button">
              <AiOutlineRight />
            </button>
          </div>
        </Swiper>
        <div className="testimonial_wrapper_bg">
          <RiDoubleQuotesR />
        </div>
      </div>
    </SectionLayout>
  );
};

const homeReviewsContent = [
  {
    id: 1,
    reviewContent:
      "The result was extraordinary; both our website traffic and conversion rates greatly increased. Our success has been greatly influenced by their digital marketing services and their knowledge of SEO, content marketing, and social media management.",
    reviewName: "Krishitha Psychiatric Care",
    reviewProfile: "Coimbatore",
  },
  {
    id: 2,
    reviewContent:
      "Our goods frequently set new standards for quality in addition to meeting industry norms. Their engineering services have made a major difference in our ability to compete in the market.",
    reviewName: "Susi Industries",
    reviewProfile: "Coimbatore",
  },
];

export default Testimonial;
