import React from "react";
import {
  AiOutlineArrowRight,
  AiOutlineMail,
  AiOutlinePhone,
} from "react-icons/ai";
import { MdOutlineLocationOn } from "react-icons/md";
import ContactModal from "../components/ContactModal";
const Contact = () => {
  return (
    <section id="contact" className="">
      <div className="container">
        <div className="contact_wrapper">
          <div className="contact_left">
            <div className="contact_left_inner">
              <h4 className=" contact_title mb-5">
                We have our branches in Chennai and Coimbatore, together we
                provide Web Development Services, App Development Services,
                Engineering Services, Testing, and Digital Marketing Services.
              </h4>
              <div>
                <ContactModal modalBtn="primary_button--white" />
              </div>
            </div>
          </div>
          <div className="contact_right">
            <div className="contact_mail mb-5">
              <span>
                <AiOutlineMail />
              </span>
              <a href="mailto:info@cubiksoft.com">info@cubiksoft.com</a>
            </div>
            <div className="contact_mail">
              <span>
                <AiOutlinePhone />
              </span>
              <a href="tel:+919487352017">+91 94873 52017</a>
            </div>
          </div>
        </div>
        <div className="py-5 locations">
          <div className="locations_left">
            <div className="locations_content">
              <div className="contact_mail mb-3">
                <span>
                  <MdOutlineLocationOn />
                </span>
                <p className="lead">Head Office</p>
              </div>
              <p className="paragraph">
                No 22,23 Sarojini street <br /> Ramnagar Coimbatore - 641009
              </p>
            </div>
          </div>
          <div className="locations_right">
            <div className="contact_mail mb-3">
              <span>
                <MdOutlineLocationOn />
              </span>
              <p className="lead">Branch</p>
            </div>
            <p className="paragraph">
              3/87A first floor, OMR <br /> Semmancheri, Chennai - 600119
            </p>
          </div>
        </div>
        <div className="contact_bottom">
          <p className="py-4">
            &copy; 2023. CubikSoft Technologies. All Rights Reserved
          </p>
        </div>
      </div>
    </section>
  );
};

export default Contact;
