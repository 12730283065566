import React, { useEffect } from "react";
import Bannerimg from "../images/banner.jpg";
// import { AiOutlineArrowRight } from "react-icons/ai";
import { gsap } from "gsap";
import ContactModal from "../components/ContactModal";
const Home = () => {
  useEffect(() => {
    gsap
      .timeline({})
      .fromTo(
        ".cubicsoft_hero_content *",
        {
          autoAlpha: 0,
          yPercent: 20,
        },
        {
          autoAlpha: 1,
          yPercent: 0,
          duration: 0.7,
          stagger: 0.04,
          ease: "power3.inOut",
        }
      )
      .fromTo(
        ".banner_image",
        {
          autoAlpha: 0,
          yPercent: 20,
        },
        {
          autoAlpha: 1,
          yPercent: 0,
          duration: 0.8,
          stagger: 0.1,
          ease: "power3.inOut",
        }
      );
  }, []);
  return (
    <>
      <section className="cubicsoft_hero_section">
        <div className="cubicsoft_hero">
          <div className="container">
            <div className="cubicsoft_hero_content">
              <h1 className="display-3  mb-4">Exploring creativity</h1>
              <h4 className="display-6 text-primary mb-4">
                In Technology and Excellence-Driven Passion
              </h4>
              <p className="paragraph mb-4">
                We are your reliable partner on the path to digital greatness
                because of our competence in Digital Marketing, Engineering
                Services, Testing, Web Development, and App Development.
              </p>
              <div>
                <ContactModal />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="banner_image">
        <div className="container">
          <img src={Bannerimg} alt="" />
        </div>
      </div>
      <div className="mb-5"></div>
    </>
  );
};

export default Home;
