import React, { useEffect, useState, useRef } from "react";
import { createPortal } from "react-dom";
import { useForm } from "react-hook-form";
import { AiOutlineArrowRight } from "react-icons/ai";
import emailjs from "emailjs-com";
import { TfiClose } from "react-icons/tfi";
import { init } from "emailjs-com";
init("cZXCQ11TvWi_iGsiP");

const ContactModal = ({ modalBtn = "" }) => {
  const [open, setOpen] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showError, setShowError] = useState(false);
  const modalRef = useRef(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const SubmitForm = (data, r) => {
    r.preventDefault();
    const templateId = "template_xe0nkh5";
    const serviceID = "service_y07h39e";
    console.log(data);
    sendFeedback(serviceID, templateId, {
      from_name: data.name,
      contact: data.mobile,
      email: data.email,
      message: data.message,
    });
    r.target.reset();
  };
  const sendFeedback = (serviceID, templateId, variables) => {
    emailjs
      .send(serviceID, templateId, variables)
      .then((res) => {
        setTimeout(() => {
          setShowSnackbar(true);
        }, 1000);
      })
      .catch((err) => {
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
        }, 5000);
      });
  };
  useEffect(() => {
    document.body.classList.toggle("no-scroll", open);
  }, [open]);
  return (
    <>
      <button
        className={`primary_button mb-5 ${modalBtn}`}
        onClick={() => setOpen(true)}
      >
        Contact Us
        <span className="primary_button--icon">
          <AiOutlineArrowRight />
        </span>
      </button>
      {open &&
        createPortal(
          <div
            className={`contact_modal ${open ? "active" : ""}`}
            ref={modalRef}
          >
            <div className="contact_modal--bg"></div>
            <div className="contact_modal_wrapper">
              <button
                className="contact_modal_close_btn"
                onClick={() => setOpen(false)}
              >
                <TfiClose />
              </button>
              <div className="contact_form_wrapper">
                <div className="mb-3">
                  <h4 className="text-primary">Connect with us</h4>
                </div>
                <form
                  id="contact_form"
                  className="contact_form mt-4"
                  method="post"
                  onSubmit={handleSubmit(SubmitForm)}
                >
                  <div className="contact_form_wrapper d-flex flex-column">
                    <div className="form_input_wrapper">
                      <input
                        className={`form-control input_field ${
                          errors.name ? "is-invalid" : ""
                        }`}
                        type="text"
                        name="name"
                        id="name_field"
                        placeholder="Name"
                        {...register("name", { required: true, maxLength: 50 })}
                      />
                      {errors.name?.type === "maxLength" && (
                        <div className="invalid-feedback">
                          Max Length not exceeding 50 letters
                        </div>
                      )}
                      {errors.name?.type === "required" && (
                        <div className="invalid-feedback">Name required</div>
                      )}
                    </div>
                    {/* <div className="form_input_wrapper">
                      <input
                        className={`form-control input_field ${
                          errors.company_name ? "is-invalid" : ""
                        }`}
                        type="text"
                        name="company_name"
                        id="name_field"
                        placeholder="Company Name"
                        {...register("company_name", { required: true })}
                      />
                      {errors.company_name?.type === "required" && (
                        <div className="invalid-feedback">
                          Company Name required
                        </div>
                      )}
                    </div> */}
                    <div className="form_input_wrapper">
                      <input
                        className={`form-control input_field ${
                          errors.email ? "is-invalid" : ""
                        }`}
                        type="email"
                        name="email"
                        id="email_field"
                        placeholder="Email"
                        {...register("email", {
                          required: true,
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          },
                        })}
                      />
                      {errors.email?.type === "pattern" && (
                        <div className="invalid-feedback">
                          Enter valid Email address
                        </div>
                      )}
                      {errors.email?.type === "required" && (
                        <div className="invalid-feedback">Email required</div>
                      )}
                    </div>
                    <div className="form_input_wrapper">
                      <input
                        className={`form-control input_field ${
                          errors.mobile ? "is-invalid" : ""
                        }`}
                        type="text"
                        name="mobile"
                        id="mobile_no"
                        placeholder="Contact No"
                        {...register("mobile", { required: true })}
                      />
                      {errors.mobile?.type === "required" && (
                        <div className="invalid-feedback">
                          Contact number required
                        </div>
                      )}
                    </div>
                    <div className="form_input_wrapper">
                      <textarea
                        className={`form-control input_field ${
                          errors.message ? "is-invalid" : ""
                        }`}
                        rows="3"
                        name="message"
                        id="message_field"
                        placeholder="Requirements"
                        {...register("message", { required: true })}
                      />
                      {errors.message?.type === "required" && (
                        <div className="invalid-feedback">Required</div>
                      )}
                    </div>
                    <div className="mt-2 mt-md-3">
                      <button className="primary_button contact-submitbtn">
                        Submit
                      </button>
                    </div>
                  </div>
                  <div className=" py-3">
                    <div
                      className={
                        showSnackbar
                          ? "snack_bar text-primary show"
                          : "snack_bar text-primary"
                      }
                    >
                      Thank you! We will contact you shortly.
                    </div>
                  </div>
                  <div className="text-danger">
                    {showError ? (
                      <h5 className="text-danger">
                        <b>
                          Something went wrong,
                          <br /> Please try after sometime
                        </b>
                      </h5>
                    ) : null}
                  </div>
                </form>
              </div>
            </div>
          </div>,
          document.body
        )}
    </>
  );
};

export default ContactModal;
