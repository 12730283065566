import React, { useEffect } from "react";
import SectionLayout from "./../components/SectionLayout";
import { Webtech } from "../components/Icons";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

const Services = () => {
  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: document.querySelector(".services_item"),
          toggleActions: "play none none reverse",
        },
      })
      .fromTo(
        document.querySelectorAll(".services_item"),
        {
          autoAlpha: 0,
          yPercent: 40,
        },
        {
          autoAlpha: 1,
          yPercent: 0,
          duration: 0.7,
          stagger: 0.04,
          ease: "power3.inOut",
        }
      );
  }, []);
  return (
    <SectionLayout title="Services" section_id="services" section_class="pb-0">
      <div className="container">
        <div className="services_title_wrapper">
          <h2 className="display-3 text-primary">End to End Solutions</h2>
        </div>
        <div className="servces_items_wrapper">
          <div className="services_items">
            {ServicesData.map((item, index) => (
              <div className="services_item" key={index}>
                <div className="services_item_left">
                  <div className="services_item_left_content">
                    <h6 className="mb-3">{item.title}</h6>
                    <p className="paragraph">{item.content}</p>
                  </div>
                </div>
                {/* <div className="services_item_right">
                  <div className="services_item_right_icon">{item.Svgicon}</div>
                </div> */}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="section_gap"></div>
      <div className="offerings_section section_padding">
        <div className="container">
          <div className="offerings">
            <h2 className="display-3 mb-4">Zero Cost Hiring</h2>
            <h4 className="display-6 mb-4">Unlock a New Hiring Revolution</h4>
            <p className="paragraph">
              Are you sick and weary of traditional hiring procedures costing so
              much money? We're reinventing recruitment by providing you with a
              cutting-edge solution called "Zero-Cost Hiring." Our revolutionary
              strategy not only helps in locating the greatest personnel but
              also removes the cost often connected with hiring. Give goodbye to
              expensive job posts, agency fees, and expensive advertising
              campaigns. You can recruit excellent people from us without
              spending a fortune.
            </p>
          </div>
        </div>
      </div>
    </SectionLayout>
  );
};

export default Services;

const ServicesData = [
  {
    id: 1,
    Svgicon: <Webtech />,
    title: "Digital Marketing",
    content:
      "Utilise digital marketing to its greatest ability to advance your company. Our skilled team specializes in developing data-driven marketing plans that increase your online visibility, engage your target audience, and produce quantifiable results. We have the resources and expertise to help organizations shine online, including pay-per-click marketing and content creation to SEO and social media management.",
  },
  {
    id: 2,
    Svgicon: <Webtech />,
    title: "Engineering Services",
    content:
      "The heart of what we do is innovation. Our engineering services offer a variety of options that are suited to your unique requirements. Our team of engineers and developers is dedicated to providing excellence irrespective of whether you need product design and development, system integration, or custom software solutions. We bring concepts to life.",
  },
  {
    id: 3,
    Svgicon: <Webtech />,
    title: "Testing",
    content:
      "Testing quality cannot be negotiated. Your digital equipment and systems will be reliable, dependable, and work flawlessly because of our thorough testing services. We take every precaution to ensure that your digital assets meet the highest standards of excellence, including performance testing, cybersecurity assessments, software testing, and quality assurance.",
  },
  {
    id: 4,
    Svgicon: <Webtech />,
    title: "App Development",
    content:
      "Use our excellent app development services to unleash the potential of creativity. We develop cutting-edge mobile applications from conception to deployment that enthrall users and provide outstanding user experiences. We have the know-how to make your app a reality, whether you're aiming for iOS, Android, or both.",
  },
  {
    id: 5,
    Svgicon: <Webtech />,
    title: "Web Development",
    content:
      "With the help of our custom web development solutions, improve your online visibility. Our team of talented web designers is proficient at creating websites that not only look amazing but also operate without a hitch. We create digital experiences that captivate your audience and provide results, ranging from responsive designs to e-commerce systems.",
  },
];
