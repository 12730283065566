import React from "react";
// import Button from "./../components/Button";
import SectionLayout from "./../components/SectionLayout";
// import { Overdemand } from "../components/Icons";
import aboutImg from "../images/about_cubiksoft.jpg";

const About = () => {
  return (
    <SectionLayout title="About" section_id="about">
      <div className="container">
        <div className="about_section_wrapper">
          <div className="about_left">
            <h4 className="display-3 text-primary mb-4">Our Company</h4>
            <p className="paragraph mt-4">
              <span className="text-primary fw-bold">
                CubikSoft Technologies
              </span>
              , Utilise our digital services to accomplish all of your goals. We
              completely understand your expectations, thus our staff, which
              works as an extension of yours, constantly makes sure that your
              projects are completed as requested and, more significantly, on
              time.
            </p>
          </div>
          <div className="about_right">
            <img
              src={aboutImg}
              alt="CubikSoft Technologies"
              title="CubikSoft Technologies"
            />
          </div>
          {/* <Button linkPath={"#"} linkName={"Contact us"} /> */}
        </div>
        {/* <div className="about_icon_section ">
          <div className="about_icons">
            {AboutIntroData.map((item, index) => (
              <div className="about_icon_item" key={index}>
                <div className="about_icon_item_top">{item.Svgicon}</div>
                <div className="about_icon_item_content">
                  <h6 className="mb-2">{item.title}</h6>
                  <p className="">{item.content}</p>
                </div>
              </div>
            ))}
          </div>
        </div> */}
      </div>
    </SectionLayout>
  );
};

export default About;

// const AboutIntroData = [
//   {
//     id: 1,
//     Svgicon: <Overdemand />,
//     title: "On Demand Platforms",
//     content:
//       "Lorem ipsum dolor sit amet consectetur adipisicing elit, Minus nulla quae nobis.",
//   },
//   {
//     id: 2,
//     Svgicon: <Overdemand />,
//     title: "On Demand Platforms",
//     content:
//       "Lorem ipsum dolor sit amet consectetur adipisicing elit, Minus nulla quae nobis.",
//   },
//   {
//     id: 3,
//     Svgicon: <Overdemand />,
//     title: "On Demand Platforms",
//     content:
//       "Lorem ipsum dolor sit amet consectetur adipisicing elit, Minus nulla quae nobis.",
//   },
// ];
